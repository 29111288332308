<template>
  <AView
    :value="value"
    :active="experienceNote.id === activeExperienceNoteId"
    :name="experienceNote.name"
    ref="experienceNote-editor-view"
    @onHover="onHover"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    @onClose="(e) => $emit('onClose', e)"
    @action="(action) => action.handler({ from: value, experienceNote })"
  >
    <template v-slot:content="{}">
      <v-card class="transparent mt-4 pa-2 elevation-0">
        <v-card-text>
          <ExperienceNoteEditorVue
            ref="form"
            v-model="experienceNote"
            :permissions="value.permissions"
            @onLinkCreate="onLinkCreate"
            @onLinkRemove="onLinkRemove"
            @action="
              (action) => action.handler({ from: value, experienceNote })
            "
          ></ExperienceNoteEditorVue>
        </v-card-text>
      </v-card>
    </template>
  </AView>
</template>
    
    
<script>
import { mapGetters, mapState } from "vuex";
import ExperienceNoteEditorVue from "../../../../../../components/wad/organisms/knowledgeBase/experienceNotes/forms/editor/ExperienceNoteEditor.vue";
import AView from "../../../../../../components/wad/organisms/layout/A-View.vue";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    AView,
    //   FormSection,
    ExperienceNoteEditorVue,
  },
  data() {
    return {
      updatedTimeout: undefined,
      experienceNote: {
        searchableKeyword: {},
      },
    };
  },
  computed: {
    ...mapState("ExperienceNotesStore", [
      "activeExperienceNoteId",
      "displayedExperienceNotes",
    ]),
    ...mapGetters("ExperienceNotesStore", ["experienceNoteById"]),
  },
  async created() {
    await this.$store.dispatch("ExperienceNotesStore/GetExperienceNote", {
      id: this.value.relation.params.experienceNoteId,
    });

    this.experienceNote = this.experienceNoteById(
      this.value.relation.params.experienceNoteId
    );
  },
  methods: {
    onHover() {
      this.$store.commit(
        "ExperienceNotesStore/setActiveId",
        this.value.relation.params.experienceNoteId
      );
    },
    async onLinkCreate(link) {
      const newLink = await this.$store.dispatch(
        "KnowledgeBaseStore/CreateLink",
        {
          link: { link },
        }
      );

      console.log("newLink: ", newLink);

      this.experienceNote.links.push({ link: newLink });
    },
    async onLinkRemove(link) {
      this.experienceNote.links = this.experienceNote.links.filter(
        (el) => el.link_id !== link.id
      );
    },
  },

  watch: {
    displayedExperienceNotes: {
      async handler(newVal) {
        if (newVal) {
          this.experienceNote = this.experienceNoteById(
            this.value.relation.params.experienceNoteId
          );
        }
      },
      deep: true,
    },
    experienceNote: {
      async handler(newVal, oldVal) {
        if (!(oldVal && oldVal.id)) return;

        if (this.$refs.form && this.$refs.form.validate())
          await this.$store.dispatch(
            "ExperienceNotesStore/UpdateExperienceNote",
            {
              experienceNote: newVal,
            }
          );
      },
      deep: true,
    },
  },
};
</script>