<template>
  <v-form ref="form" v-model="pValid" lazy-validation>
    <HelpFormInputVue
      dark
      v-model="form.name"
      :rules="nameRules"
      label="Note Title"
      :helpText="'Provide a title for the Note to make it more recognizable'"
      required
    ></HelpFormInputVue>

    <HelpFormInputVue
      dark
      v-model="form.web_link"
      :rules="linkRules"
      label="Web Link"
      :helpText="'Provide a title for the Note to make it more recognizable'"
      required
    ></HelpFormInputVue>

    <HelpFormInputVue
      dark
      v-model="form.searchableKeyword.name"
      :rules="nameRules"
      label="Searchable Keyword"
      :helpText="'This text will be used to adjust a search in the right way. E.g. this data will be used in Leads-Analyzer'"
      required
    ></HelpFormInputVue>

    <HelpFormInputVue
      dark
      :rows="4"
      v-model="form.description"
      :rules="descriptionRules"
      label="Internal Description"
      :helpText="'You can provide a description for the record to make it more clear and useful. Please not that this description will be used internally.'"
      :textarea="true"
      required
    ></HelpFormInputVue>

    <HelpFormInputVue
      dark
      :rows="4"
      v-model="form.marketing_description"
      label="Marketing Description"
      :helpText="'This description will be used in the proposals and generated slides. Please make sure that it make sense for the customers and businesses'"
      :textarea="true"
      required
    ></HelpFormInputVue>

    <HelpFormInputVue
      dark
      :rows="4"
      v-model="form.solved_tasks"
      label="Solved Tasks"
      :helpText="'The List of tasks that was solved in this experience record. Or a potential tasks that can be solved using it.'"
      :textarea="true"
      required
    ></HelpFormInputVue>

    <HelpFormInputVue
      dark
      :rows="4"
      v-model="form.pros"
      label="🔺Advantages"
      :helpText="'Advantages that were identified in this experience record '"
      :textarea="true"
      required
    ></HelpFormInputVue>
    <HelpFormInputVue
      dark
      :rows="4"
      v-model="form.cons"
      label="🔻Disadvantages"
      :helpText="'Disadvantages that were identified in this experience record '"
      :textarea="true"
      required
    ></HelpFormInputVue>

    <!-- Tags -->
    <FormSection
      class="mt-0"
      underline
      right
      :label="'Tags'"
      :icon="'mdi-tag-multiple-outline'"
      :actions="tagActions"
      :editable="!!tagActions.length"
      @action="(action) => $emit('action', action)"
    ></FormSection>

    <SelectorKnowledgeBaseTagsVue
      dark
      autoload
      label="Tags"
      helpText="This is an actual Service Status"
      return-object
      v-model="tags"
      multiple
    >
    </SelectorKnowledgeBaseTagsVue>

    <!-- Business Domains -->
    <FormSection
      class="mt-0"
      underline
      right
      :label="'Business Domains'"
      :icon="'mdi-tag-multiple-outline'"
      :actions="tagActions"
      :editable="!!tagActions.length"
      @action="(action) => $emit('action', action)"
    ></FormSection>
    <SelectorBusinessDomains
      dark
      :icon="''"
      :label="'Business Domains'"
      :helpText="'Please select all business domains that relates to this company or potentially may be used.'"
      required
      multiple
      return-object
      v-model="domains"
      autoload
    >
    </SelectorBusinessDomains>

    <!-- Terms -->
    <FormSection
      class="mt-0"
      underline
      right
      :label="'Terms'"
      :icon="'mdi-tag-multiple-outline'"
      :actions="tagActions"
      :editable="!!tagActions.length"
      @action="(action) => $emit('action', action)"
    ></FormSection>
    <SelectorGlossaryTerm
      dark
      :icon="''"
      :label="'Glossary Terms'"
      :helpText="'Please connect all terms associated with this experience record'"
      required
      multiple
      return-object
      v-model="terms"
      autoload
    >
    </SelectorGlossaryTerm>

    <!-- Useful Links -->

    <FormSection
      :label="'Useful Links'"
      class="mt-0"
      :icon="'mdi-link-variant'"
      :actions="actions"
      @onCreate="() => $emit('onTagCreate')"
      :editable="false"
      underline
      right
    ></FormSection>

    <HelpFormInputVue
      dark
      class="mt-4"
      outlined
      v-model="newLink"
      :rules="linkRules"
      label="New Link"
      :helpText="'This text will be used to adjust a search in the right way. E.g. this data will be used in Leads-Analyzer'"
      required
      @onEnter="onNewLinkCreate"
    ></HelpFormInputVue>
    <LinksList
      v-if="form.links && form.links.length > 0"
      :value="form.links"
      @onLinkRemove="onLinkRemove(link)"
    ></LinksList>
  </v-form>
</template>
      
      
<script>
import SelectorKnowledgeBaseTagsVue from "../../../../../atoms/common/autocompletes/SelectorKnowledgeBaseTags.vue";
import HelpFormInputVue from "../../../../../atoms/common/inputs/HelpFormInput.vue";
import LinksList from "../../../links/LinksList.vue";
import FormSection from "../../../../../atoms/common/FormSection.vue";
import _ from "lodash";
import { PermissionsHelper } from "../../../../../../helpers/permissions.helper";
import { TAGS_BUTTONS } from "../../../../../../../views/src/apps/knowledge-base/constants/actions.gh";
import SelectorBusinessDomains from "../../../../../atoms/common/autocompletes/SelectorBusinessDomains.vue";
import SelectorGlossaryTerm from "../../../../../atoms/common/autocompletes/SelectorGlossaryTerm.vue";

export default {
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: false,
    },
    onLinkCreate: {
      type: Function,
      default: async () => {},
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    tagActions: function () {
      return PermissionsHelper.getActions(
        [TAGS_BUTTONS.CREATE_NEW],
        this.permissions
      );
    },
  },
  data() {
    const { tags, domains, terms } = this.prepareLists(this.value);

    return {
      // Form
      pValid: this.valid,
      form: this.value,

      tags,
      domains,
      terms,
      newLink: "",

      // ======rules
      nameRules: [(v) => !!v || "Term Name is required"],
      descriptionRules: [(v) => !!v || "Description is required"],

      linkRules: [
        (v) =>
          !v || /(https?:\/\/[^\s]+)/.test(v) || "Link should be a proper link",
      ],
      actions: [
        {
          name: "Create",
          description: "Will create new Tag",
          icon: "mdi-plus",
          color: "#23842a",
          on: "onCreate",
        },
      ],
    };
  },
  components: {
    HelpFormInputVue,
    SelectorKnowledgeBaseTagsVue,
    FormSection,
    LinksList,
    SelectorBusinessDomains,
    SelectorGlossaryTerm,
  },
  methods: {
    prepareLists(value) {
      let tags = [];
      let domains = [];
      let terms = [];

      if (value.tags && value.tags.length) tags = value.tags.map((t) => t.tag);

      if (value.domains && value.domains.length)
        domains = value.domains.map((t) => t.domain);

      if (value.terms && value.terms.length)
        terms = value.terms.map((t) => t.term);

      return { tags, terms, domains };
    },

    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    onNewLinkCreate() {
      this.$emit("onLinkCreate", this.newLink);
    },
    onLinkRemove(link) {
      this.$emit("onLinkRemove", link);
    },
  },
  watch: {
    value: {
      handler(newVal) {
        const { tags, terms, domains } = this.prepareLists(newVal);

        this.tags = Object.assign(this.tags, tags);
        this.domains = Object.assign(this.domains, domains);
        this.terms = Object.assign(this.terms, terms);

        this.newLink = "";

        this.form = newVal;
      },
      deep: true,
    },
    form: {
      handler(newVal) {
        console.log("FORM UPDATED");
        this.$emit("input", newVal);
      },
      deep: true,
    },
    pValid(newVal) {
      this.$emit("onValid", newVal);
    },
    tags: {
      handler(newVal) {
        console.log("TAGS UPDATED");
        this.form.tags = newVal.map((tag, i, arr) => {
          const existed = _.find(arr, (t) => t.tag_id === tag.id);

          return existed
            ? existed
            : {
                tag,
              };
        });
      },
      deep: true,
    },
    domains: {
      handler(newVal) {
        console.log("domains");

        this.form.domains = newVal.map((domain, i, arr) => {
          const existed = _.find(
            arr,
            (t) => t.business_domain_id === domain.id
          );

          return existed
            ? existed
            : {
                domain,
              };
        });
      },
      deep: true,
    },
    terms: {
      handler(newVal) {
        this.form.terms = newVal.map((term, i, arr) => {
          const existed = _.find(arr, (t) => t.term_id === term.id);

          return existed
            ? existed
            : {
                term,
              };
        });
      },
      deep: true,
    },
  },
};
</script>
      
      
      <style lang="scss" scoped>
.term-col {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  flex-grow: 1;
  max-width: 100%;
}
</style>